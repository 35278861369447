import React from 'react';
import { AutoSizer, WindowScroller, List } from 'react-virtualized';

import VirtualizedListItem from './VirtualizedListItem';

import { VirtualizedContainerProps } from './types';

interface Props<T> extends VirtualizedContainerProps<T> {
  itemSize: number;
}

export default function VirtualizedList<T>({
  dataset,
  itemSize,
  renderData,
}: Props<T>) {
  return (
    <WindowScroller>
      {({ height, isScrolling, onChildScroll, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              autoHeight
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              overscanRowCount={5}
              rowCount={dataset.length}
              rowHeight={itemSize}
              rowRenderer={({ index, style, key }) => (
                <VirtualizedListItem
                  data={dataset[index]}
                  key={key}
                  renderData={renderData}
                  style={style}
                />
              )}
              scrollTop={scrollTop}
              width={width}
            />
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
}
