import React from 'react';

import { useQuery } from '@apollo/react-hooks';

import styled from 'styled-components';
import { compose, spacing, sizing } from '@material-ui/system';

import { LAYOUT_STATE_QUERY } from '../Layout';

interface Props {
  children: React.ReactNode;
}

export default function PageBody({ children }: Props) {
  const { data } = useQuery(LAYOUT_STATE_QUERY);

  return (
    <Container
      maxWidth={data.layout.maxWidth}
      mx="auto"
      my={{ xs: 7, sm: 15 }}
      px={{ xs: 1, sm: 2, lg: 0 }}
    >
      {children}
    </Container>
  );
}

const Container = styled.div`
  ${compose(
    spacing,
    sizing,
  )}
`;
