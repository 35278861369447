import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  description?: string;
  keywords?: string[];
  lang?: string;
  meta?: Array<{ content: string; name: string }>;
  title: string;
}

export default function SEO({
  description = 'CRPM',
  lang = 'fr',
  meta = [],
  keywords = [],
  title,
}: Props) {
  const { site } = useStaticQuery(query);
  const metaDescription = description || site.siteMetadata.description;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <meta content={metaDescription} name="description" />
      <meta content={title} name="og:title" />
      <meta content={metaDescription} name="og:description" />
      <meta content="website" name="og:type" />
      <meta content="summary" name="twitter:card" />
      <meta content={site.siteMetadata.author} name="twitter:creator" />
      <meta content={title} name="twitter:title" />
      <meta content={metaDescription} name="twitter:description" />
      <meta
        content={[...site.siteMetadata.keyWords, ...keywords].join(', ')}
        name="keywords"
      />
      {meta.map(({ content, name }) => (
        <meta content={content} key={name} name={name} />
      ))}
    </Helmet>
  );
}

const query = graphql`
  query defaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        keyWords
      }
    }
  }
`;
