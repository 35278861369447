import React, { useEffect, useState, useContext } from 'react';

import { debounce } from '../../utils';

import { TableOfContentContext } from './TableOfContent';
import TableOfContentItem from './TableOfContentItem';

interface Props {
  items: Array<[string, string]>;
}

// casted as any here because of https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356#issuecomment-336384210
export default function TableOfContentItems({ items }: Props): any {
  const { offset, positions } = useContext(TableOfContentContext);
  const [scrollPosition, setScrollPosition] = useState(0);

  const listenScroll = debounce(
    () =>
      setScrollPosition(
        window.pageYOffset || document.documentElement.scrollTop,
      ),
    0,
  );

  useEffect(() => {
    window.addEventListener('scroll', listenScroll);
    return () => window.removeEventListener('scroll', listenScroll);
  }, []);

  function getActiveId() {
    const active = positions.find((position, index) => {
      return (
        scrollPosition >= position[1] - offset &&
        (positions[index + 1]
          ? scrollPosition < positions[index + 1][1] - offset
          : true)
      );
    });
    return active && active[0];
  }

  return items.map(([id, value]) => (
    <TableOfContentItem
      activeId={getActiveId()}
      contentId={id}
      key={id}
      offset={offset}
    >
      {value}
    </TableOfContentItem>
  ));
}
