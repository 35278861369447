import React, { useState } from 'react';

import styled from 'styled-components';
import {
  Typography,
  ButtonBase,
  Stepper,
  Step,
  StepLabel,
  Button,
} from '@material-ui/core';

import { PageSection } from '../Page';

import useTrainingSessions from './useTrainingSessions';
import { Session } from './types';

const SessionsContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 32px;
`;

const SessionBox = styled(({ active, ...props }) => <ButtonBase {...props} />)`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-color: ${({ theme }) => theme.palette.divider};
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 56px 16px;
  transition: ${({ theme }) => theme.transitions.create('background-color')};
  background-color: ${({ theme, active }) =>
    active ? theme.palette.background.paper : 'transparent'};
`;

const Title = styled(Typography)`
  padding-bottom: 24px;
  text-align: center;
`;

const StyledStepper = styled(Stepper)`
  background-color: transparent;
  margin: 56px 0;
  width: 100%;
`;

const ActionContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit);
  grid-template-rows: auto;
  justify-content: right;
  margin-top: 32px;
`;

interface Props {
  trainingId: string;
}

interface SessionReducer {
  (x: { [key: string]: Session[] }, y: Session): {
    [key: string]: Session[];
  };
}

const sessionReducer: SessionReducer = (acc, session) => {
  const location = session.sessionPlanning.locationName || 'Lieu à définir';
  const previous = acc[location] || [];
  return {
    ...acc,
    [location]: [...previous, session],
  };
};

export default function TrainingBooking({ trainingId }: Props) {
  const { sessions } = useTrainingSessions(trainingId);
  const [activeStep, setActiveStep] = useState(0);
  const [selected, selectSession] = useState();

  const locations = sessions.reduce(sessionReducer, {});

  const steps = [
    ['sessions', 'Sélectionnez votre session'],
    ['connection', 'Connectez-vous'],
    ['sessions', 'Confirmation'],
  ];

  return (
    <PageSection colorScheme="lightGrey">
      <Title color="inherit" variant="h2">
        {'Inscrivez-vous à cette formation'}
      </Title>
      <StyledStepper activeStep={activeStep} alternativeLabel>
        {steps.map(step => (
          <Step key={step[0]}>
            <StepLabel>{step[1]}</StepLabel>
          </Step>
        ))}
      </StyledStepper>
      {Object.entries(locations).map(([location, sessions]) => (
        <div key={location}>
          <Typography variant="h4">{location} </Typography>
          <SessionsContainer>
            {sessions.map(session => (
              <SessionBox
                active={selected === session.id}
                key={session.id}
                onClick={() =>
                  selectSession(selected === session.id ? '' : session.id)
                }
              >
                <Typography color="textPrimary" variant="subtitle2">
                  {session.localizedSessionDate}
                </Typography>
              </SessionBox>
            ))}
          </SessionsContainer>
        </div>
      ))}
      <ActionContainer>
        <Button
          disabled={activeStep === 0}
          onClick={() => setActiveStep(activeStep - 1)}
        >
          {'Précédent'}
        </Button>
        <Button
          color="primary"
          disabled={activeStep === steps.length}
          onClick={() => setActiveStep(activeStep + 1)}
          variant="contained"
        >
          {activeStep >= steps.length ? 'Inscription' : 'Suivant'}
        </Button>
      </ActionContainer>
    </PageSection>
  );
}
