import React, { useContext, createRef, useEffect } from 'react';

import { TableOfContentContext } from './TableOfContent';

interface Props {
  children: React.ReactChild[] | React.ReactChild;
  id: string;
}

export default function Content({ children, id }: Props) {
  const ref = createRef<HTMLDivElement>();
  const { registerPosition } = useContext(TableOfContentContext);

  useEffect(() => {
    if (ref.current) {
      registerPosition(
        id,
        ref.current.getBoundingClientRect().top + window.scrollY,
      );
    }
  }, [ref.current]);

  return (
    <div id={id} ref={ref}>
      {children}
    </div>
  );
}
