import React from 'react';

import styled from 'styled-components';
import { Typography, Divider } from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import GetAppIcon from '@material-ui/icons/GetApp';
import ForumIcon from '@material-ui/icons/Forum';

import { FlatColorButton } from '../Button';
import { TableOfContentItems } from '../TableOfContent';

const Container = styled.div<{ offset: number }>`
  position: sticky;
  top: ${({ offset }) => offset}px;
  width: 240px;
`;

const StyledDivider = styled(Divider)`
  margin: 24px 0;
`;

const ButtonText = styled.span`
  margin-left: 8px;
`;

interface Props {
  offset: number;
  toc: Array<[string, string]>;
  trainingName: string;
  pricing: {
    plannedDays: number;
    minPrice: number;
  };
  plannedSessions: boolean;
}

export default function Sidebar({
  offset,
  toc,
  trainingName,
  pricing,
  plannedSessions,
}: Props) {
  return (
    <Container offset={offset}>
      <Typography color="textPrimary" gutterBottom variant="subtitle2">
        {trainingName}
      </Typography>
      <Typography variant="body2">
        {plannedSessions
          ? `${pricing.plannedDays} jour${
              pricing.plannedDays > 1 ? 's' : ''
            }, dès ${pricing.minPrice}.-`
          : 'En cours de planification'}
      </Typography>
      <StyledDivider />
      <TableOfContentItems items={toc} />
      <StyledDivider />
      {plannedSessions && (
        <FlatColorButton
          active
          color="primary"
          fullWidth
          justifyContent="flex-start"
          mb={1}
          variant="contained"
        >
          <AssignmentTurnedInIcon />
          <ButtonText>{'Dates et inscription'}</ButtonText>
        </FlatColorButton>
      )}
      <FlatColorButton
        color="primary"
        fullWidth
        justifyContent="flex-start"
        mb={1}
        variant="contained"
      >
        <GetAppIcon />
        <ButtonText>{'Brochure'}</ButtonText>
      </FlatColorButton>
      <FlatColorButton
        color="primary"
        fullWidth
        justifyContent="flex-start"
        mb={1}
        variant="contained"
      >
        <ForumIcon />
        <ButtonText>{'Renseignements'}</ButtonText>
      </FlatColorButton>
    </Container>
  );
}
