import React from 'react';

import { Link, graphql, useStaticQuery } from 'gatsby';
import BackgroundImage, {
  IBackgroundImageProps,
} from 'gatsby-background-image';

import styled from 'styled-components';

import {
  compose,
  spacing,
  display,
  flexbox,
  palette,
  typography,
  SpacingProps,
  PaletteProps,
} from '@material-ui/system';

import { Paper, Typography, Button } from '@material-ui/core';

import useTrainingSessions from './useTrainingSessions';
import { FluidImage } from './types';

interface Props {
  id: string;
  name: string;
  slug: string;
  subtitle: string;
  training_pictureSharp?: FluidImage;
}

export default function TrainingCard({
  name,
  subtitle,
  slug,
  training_pictureSharp,
  id,
}: Props) {
  const { allFile } = useStaticQuery(placeholderImageQuery);
  const { sessions, loading } = useTrainingSessions(id);

  return (
    <Card overflow="hidden">
      <StyledBackgroundImage
        color="text.inverted"
        fluid={[
          'linear-gradient(0deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0.00) 50%)',
          (training_pictureSharp &&
            training_pictureSharp.childImageSharp.fluid) ||
            allFile.edges[0].node.childImageSharp.fluid,
        ]}
        p={2}
      >
        <StyledTypography
          color="inherit"
          fontSize={12}
          fontWeight={600}
          textTransform="uppercase"
        >
          {'Prochaine session'}
        </StyledTypography>
        <StyledTypography color="inherit" fontSize={12} fontWeight={600}>
          {loading
            ? 'Chargement des informations'
            : (sessions[0] && sessions[0].localizedSessionDate) ||
              'En cours de planification'}
        </StyledTypography>
      </StyledBackgroundImage>
      <Content flex="1 0 auto" p={2}>
        <StyledTypography fontSize={18} variant="h5">
          {name}
        </StyledTypography>
        <StyledTypography pt={4} variant="body1">
          {subtitle}
        </StyledTypography>
      </Content>
      <Action flex="0 0 auto" pb={2} px={2}>
        <Button
          component={Link}
          fullWidth
          size="large"
          to={`/formations/${slug}`}
        >
          {'En savoir plus'}
        </Button>
      </Action>
    </Card>
  );
}

const Card = styled(({ flexDirection, ...props }) => <Paper {...props} />)`
  ${compose(
    spacing,
    flexbox,
    display,
  )}
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 240px 1fr auto;
`;

const Content = styled.div`
  ${compose(
    spacing,
    flexbox,
  )}
`;

// TODO: There is certainly a better typing solution here
const StyledBackgroundImage = styled<
  React.FunctionComponent<SpacingProps & PaletteProps & IBackgroundImageProps>
>(props => <BackgroundImage {...props} />)`
  ${compose(
    palette,
    spacing,
  )}
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: start;
  align-items: end;
`;

const StyledTypography = styled(({ textTransform, ...props }) => (
  <Typography {...props} />
))`
  ${spacing}
  ${typography}
  text-transform: ${({ textTransform }) => textTransform || 'none'}
`;

const Action = styled.div<{ flex: string; pb: number; px: number }>`
  ${spacing}
`;

const placeholderImageQuery = graphql`
  query getPlaceholderForTrainingCard {
    allFile(filter: { name: { regex: "/placeholder/" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
