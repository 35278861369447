import React, { useState } from 'react';
import { isFuture } from 'date-fns';
import { useQuery } from '@apollo/react-hooks';

import styled from 'styled-components';
import {
  compose,
  spacing,
  palette,
  borders,
  SpacingProps,
  BordersProps,
} from '@material-ui/system';

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import { Typography } from '@material-ui/core';

import { gradients } from '../../styles/functions';

import Button from '../Button';
import { LAYOUT_STATE_QUERY } from '../Layout';

import { Session } from '../Trainings/types';

interface Props {
  sessions: Session[];
}

export default function Sessions({ sessions }: Props) {
  const { data } = useQuery(LAYOUT_STATE_QUERY);
  const [selected, select] = useState<string | null>(null);

  return (
    <Container
      borderTop="default"
      id="sessions"
      mt={{ xs: 7, sm: 15 }}
      pt={{ xs: 7, sm: 15 }}
    >
      {sessions
        .filter(({ start }) => isFuture(start))
        .slice(0, 3)
        .map(({ sessionId, frenchDate, city }) => {
          return (
            <StyledSession
              borderRadius="medium"
              colorScheme={data.layout.colorScheme}
              key={sessionId}
              onClick={() => select(selected === sessionId ? null : sessionId)}
              px={{ xs: 5 }}
              py={{ xs: 4 }}
              selected={selected === sessionId}
            >
              {selected === sessionId ? (
                <RadioButtonCheckedIcon />
              ) : (
                <RadioButtonUncheckedIcon />
              )}
              <Info>
                <Typography align="right" variant="subtitle2">
                  {frenchDate.charAt(0).toUpperCase() +
                    frenchDate.substring(1).toLowerCase()}
                </Typography>
                <Typography variant="body1">
                  {city.charAt(0).toUpperCase() +
                    city.substring(1).toLowerCase()}
                </Typography>
              </Info>
            </StyledSession>
          );
        })}
    </Container>
  );
}

const Container = styled.div<SpacingProps & BordersProps>`
  ${compose(
    spacing,
    borders,
  )}
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(392px, 1fr));
  grid-column-gap: ${({ theme }) => theme.spacing(4)};
  grid-row-gap: ${({ theme }) => theme.spacing(4)};
`;

const StyledSession = styled(
  ({ colorScheme, borderRadius, gridColumn, ...props }) => (
    <Button {...props} />
  ),
)`
  ${compose(
    gradients,
    spacing,
    palette,
    borders,
  )}
  justify-content: space-between;
  text-transform: none;
  background-size: 400%;
  background-color: ${({ theme, colorScheme, selected }) =>
    selected
      ? theme.palette[colorScheme].main
      : theme.palette.background.lightGrey};
  &:hover {
    background-color: ${({ theme, colorScheme, selected }) =>
      selected
        ? theme.palette[colorScheme].main
        : theme.palette.background.lightGrey};
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
