import React from 'react';
import scrollToElement from 'scroll-to-element';

import { Typography } from '@material-ui/core';

import styled from 'styled-components';

interface Props {
  children: string;
  activeId?: string;
  contentId: string;
  offset: number;
}

export default function TableOfContentItem({
  children,
  contentId,
  activeId,
  offset,
}: Props): JSX.Element {
  return (
    <Item
      active={activeId === contentId}
      onClick={handleScrollToHash(contentId, offset)}
      variant="body2"
    >
      {children}
    </Item>
  );
}

const Item = styled(({ active, ...props }) => <Typography {...props} />)`
  font-weight: ${({ active }) => (active ? 600 : 400)};
  line-height: 32px;
  cursor: pointer;
`;

function handleScrollToHash(
  id: string,
  offset: number,
): React.MouseEventHandler {
  return (e): void => {
    e.preventDefault();
    if (id) {
      scrollToElement(`#${id}`, {
        offset: -offset,
        duration: 750,
        ease: 'inOutCirc',
      });
    }
  };
}
