import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import format from 'date-fns/format';
import isSameYear from 'date-fns/isSameYear';
import fr from 'date-fns/locale/fr';

import { Session, DateObject, SessionsQueryResults } from './types';

function parseDateObject(dateObject: DateObject) {
  return new Date(dateObject.years, dateObject.months - 1, dateObject.days);
}

function sortByStartDate(a: Session, b: Session) {
  const aDate = parseDateObject(a.sessionPlanning.startDate);
  const bDate = parseDateObject(b.sessionPlanning.startDate);

  if (aDate < bDate) {
    return -1;
  } else if (aDate > bDate) {
    return 1;
  }
  return 0;
}

function formatDate(session: Session): string {
  const startDate = parseDateObject(session.sessionPlanning.startDate);
  const endDate = parseDateObject(session.sessionPlanning.endDate);

  return `Du ${format(
    startDate,
    `dd LLLL${!isSameYear(startDate, endDate) ? ' yyyy' : ''}`,
    {
      locale: fr,
    },
  )} au ${format(endDate, 'dd LLLL yyyy', {
    locale: fr,
  })}`;
}

export const sessionsQuery = gql`
  query getSessionsForTraining($trainingId: ID!) {
    allSessionsByTraining(trainingId: $trainingId) {
      id
      name
      minimumCapacity
      maximumCapacity
      fullyBooked
      registrationCount
      attendeesCount
      offer {
        active
        courseId
        name
        reference
        offerType {
          ...Value
        }
        startDate {
          ...Date
        }
        endDate {
          ...Date
        }
        principalDetail {
          ...OfferDetail
        }
        appendiceDetail {
          ...OfferDetail
        }
      }
      sessionPlanning {
        locationName
        plannedDays
        startDate {
          ...Date
        }
        endDate {
          ...Date
        }
      }
    }
  }
  fragment Price on PriceObject {
    value
    codeIsoCurrency
  }
  fragment Date on DateObject {
    years
    months
    days
    hours
    minutes
    seconds
  }

  fragment Value on ListValue {
    code
    name
  }
  fragment OfferDetail on OfferDetail {
    id
    externalCode
    name
    serviceType {
      ...Value
    }
    tarificationType {
      ...Value
    }
    amountHt {
      ...Price
    }
    discountType {
      ...Value
    }
    discountHt {
      ...Price
    }
    discountRate
    vat {
      ...Value
    }
    optional
    lastUpdateDate {
      ...Date
    }
  }
`;

export default function useTrainingSessions(
  trainingId: string,
): SessionsQueryResults {
  const { data: { allSessionsByTraining = [] } = {}, loading } = useQuery(
    sessionsQuery,
    {
      variables: { trainingId },
    },
  );

  return {
    sessions: allSessionsByTraining
      .sort(sortByStartDate)
      .map((session: Session) => ({
        ...session,
        localizedSessionDate: formatDate(session),
      })),
    loading,
  };
}
