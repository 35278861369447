import React from 'react';

import styled from 'styled-components';

import { VirtualizedItemProps } from './types';

interface Props<T> extends VirtualizedItemProps<T> {
  gutterSize: number;
}

export default function VirtualizedGridCell<T>({
  style,
  data,
  renderData,
  gutterSize,
}: Props<T>) {
  return (
    <Container gutterSize={gutterSize} style={style}>
      {!!data && renderData(data)}
    </Container>
  );
}

const Container = styled.div<{ gutterSize: number }>`
  padding: ${({ gutterSize }) => gutterSize / 2}px;
`;
