import React, { useState } from 'react';

import styled from 'styled-components';

import { Typography, IconButton, Hidden, Theme } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewListIcon from '@material-ui/icons/ViewList';
import { spacing } from '@material-ui/system';

import { VirtualizedGrid, VirtualizedList } from '../Virtualized';
import { Filters } from '../Filters';

import TrainingCard from './TrainingCard';
import TrainingListItem from './TrainingListItem';

import { Training } from './types';

interface Props {
  trainings: Array<Training>;
}

export default function Trainings({ trainings }: Props) {
  const [view, setView] = useState('card');

  return (
    <Container>
      <Hidden smDown>
        <Filters />
      </Hidden>
      <Results>
        <GridHeader mb={4} pb={1}>
          <Typography color="textPrimary" variant="body1">{`${
            trainings.length
          } formation${trainings.length > 1 ? 's' : ''}`}</Typography>
          <ViewButtonContainer>
            <IconButton onClick={() => setView('card')}>
              <StyledModuleIcon active={view === 'card'} />
            </IconButton>
            <IconButton edge="end" onClick={() => setView('list')}>
              <StyledListIcon active={view === 'list'} />
            </IconButton>
          </ViewButtonContainer>
        </GridHeader>
        {view === 'card' ? (
          <VirtualizedGrid
            dataset={trainings}
            gutterSize={16}
            renderData={data => <TrainingCard key={data.name} {...data} />}
          />
        ) : (
          <VirtualizedList
            dataset={trainings}
            itemSize={156}
            renderData={data => <TrainingListItem key={data.name} {...data} />}
          />
        )}
      </Results>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 288px 1fr;
  grid-gap: 72px;
  align-items: start;
`;

const Results = styled.div`
  position: 'relative';
  width: 100%;
`;

const GridHeader = styled.div`
  ${spacing}
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-bottom: ${({ theme }) => `solid 1px ${theme.palette.divider}`};
`;

const ViewButtonContainer = styled.div`
  justify-self: end;
`;

const isActiveIcon = ({ active, theme }: { active: boolean; theme: Theme }) =>
  active ? theme.palette.text.primary : theme.palette.text.hint;

const StyledModuleIcon = styled(({ active, ...props }) => (
  <ViewModuleIcon {...props} />
))`
  color: ${isActiveIcon};
`;

const StyledListIcon = styled(({ active, ...props }) => (
  <ViewListIcon {...props} />
))`
  color: ${isActiveIcon};
`;
