import React from 'react';

import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';

import { spacing, SpacingProps } from '@material-ui/system';

import { LAYOUT_STATE_QUERY } from '../Layout';

interface Props {
  children: React.ReactNode;
  colorScheme?: string;
  divider?: boolean;
}

export default function PageSection({
  children,
  colorScheme = 'default',
  divider = false,
}: Props) {
  const { data } = useQuery(LAYOUT_STATE_QUERY);

  return (
    <Container colorScheme={colorScheme}>
      <Content
        divider={divider}
        maxWidth={data.layout.maxWidth}
        px={{ xs: 1, sm: 2, lg: 0 }}
      >
        {children}
      </Content>
    </Container>
  );
}

const Container = styled.div<{ colorScheme: string }>`
  background-color: ${({ theme, colorScheme }) => {
    if (colorScheme === 'dark') {
      return theme.palette.background.contrast.main;
    }
    return theme.palette.background[colorScheme];
  }};
  color: ${({ colorScheme, theme }) =>
    colorScheme === 'dark'
      ? theme.palette.text.inverted
      : theme.palette.text.primary};
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
`;

const Content = styled.div<
  SpacingProps & { maxWidth: number; divider: boolean }
>`
  ${spacing}
  max-width: ${({ maxWidth }) => maxWidth}px;
  width: 100%;
  padding-top: 112px;
  padding-bottom: 112px;
  border-bottom: ${({ theme, divider }) =>
    divider ? `solid 1px ${theme.palette.divider}` : 'none'};
`;
