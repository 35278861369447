import React from 'react';
import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';

import { VirtualizedItemProps } from './types';

export default function VirtualizedListItem<T>({
  data,
  style,
  renderData,
}: VirtualizedItemProps<T>) {
  return (
    <ItemContainer style={style}>
      {!!data && <ListItem>{renderData(data)}</ListItem>}
    </ItemContainer>
  );
}

const ItemContainer = styled.div`
  padding: 8px;
`;

const ListItem = styled(ButtonBase)`
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: block;
  transition: ${({ theme }) => theme.transitions.create('background-color')};
  &:hover {
    background-color: ${({ theme }) => theme.palette.background.lightGrey};
  }
`;
