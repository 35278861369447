import React from 'react';

import { useQuery } from '@apollo/react-hooks';
import { QueryResult } from '@apollo/react-common';

import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import RichText from '../RichText';
import { TableOfContent, Content } from '../TableOfContent';

import { LAYOUT_STATE_QUERY } from '../Layout';

import { PageSection } from '../Page';

import Sidebar from './Sidebar';
import useTrainingSessions from './useTrainingSessions';
import { Training } from './types';

const Container = styled.div`
  display: grid;
  justify-content: center;
  align-items: start;
  position: relative;
  grid-template-columns: 1fr;
  ${({ theme }) => `
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      grid-template-columns: 240px 1fr;
    }
  `}
  grid-gap: 32px;
`;

const Main = styled.div`
  max-width: 720px;
`;

const SectionTitle = styled(({ textAlign, mt, tocRef, ...props }) => (
  <Typography ref={tocRef} {...props} />
))`
  margin-bottom: 24px;
  margin-top: ${({ mt }) => (typeof mt !== 'undefined' ? mt * 8 : '72')}px;
`;

const SectionContent = styled(RichText)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 18px;
  line-height: 32px;
  text-align: left;
`;

interface Props {
  training: Training;
}

export default function TrainingDescription({ training }: Props) {
  const {
    data: { layout },
  }: QueryResult = useQuery(LAYOUT_STATE_QUERY);

  const { sessions } = useTrainingSessions(training.id);

  const pricing = sessions.reduce(
    (
      min,
      {
        sessionPlanning: { plannedDays },
        offer: {
          principalDetail: { tarificationType, amountHt },
        },
      },
      index,
    ) => {
      let priceMultiplier = 1;

      if (tarificationType.code === 'JP' || tarificationType.code === 'J') {
        priceMultiplier = plannedDays;
      }

      if (index === 0) {
        return { minPrice: amountHt.value * priceMultiplier, plannedDays };
      }

      if (amountHt.value < min.minPrice) {
        return { minPrice: amountHt.value * priceMultiplier, plannedDays };
      }

      return { minPrice: amountHt.value * priceMultiplier, plannedDays };
    },
    { minPrice: 0, plannedDays: 0 },
  );

  const tocKeys: Array<[string, string]> = [
    ['introduction', 'Introduction'],
    ['objectives', 'Les objectifs de la formation'],
    ['concerned', "A qui s'addresse cette formation"],
    ['agenda', 'Programme'],
    ['prerequisites', 'Prérequis'],
    ['additional_information', 'Informations complémentaires'],
  ];

  return (
    <PageSection>
      <TableOfContent offset={layout.headerHeight}>
        <Container>
          <Sidebar
            offset={layout.headerHeight + 112}
            plannedSessions={sessions.length > 0}
            pricing={pricing}
            toc={tocKeys.filter(([key]) => !!training[key])}
            trainingName={training.name}
          />
          <Main>
            {training.introduction && (
              <Content id="introduction">
                <SectionContent text={training.introduction} />
              </Content>
            )}
            {training.objectives && (
              <Content id="objectives">
                <SectionTitle color="textPrimary" textAlign="left" variant="h4">
                  {'Les objectifs de la formation'}
                </SectionTitle>
                <SectionContent text={training.objectives} />
              </Content>
            )}
            {training.concerned && (
              <Content id="concerned">
                <SectionTitle color="textPrimary" textAlign="left" variant="h4">
                  {"A qui s'addresse cette formation"}
                </SectionTitle>
                <SectionContent text={training.concerned} />
              </Content>
            )}
            {training.agenda && (
              <Content id="agenda">
                <SectionTitle color="textPrimary" textAlign="left" variant="h4">
                  {'Programme'}
                </SectionTitle>
                <SectionContent text={training.agenda} />
              </Content>
            )}
            {training.prerequisites && (
              <Content id="prerequisites">
                <SectionTitle color="textPrimary" textAlign="left" variant="h4">
                  {'Prérequis'}
                </SectionTitle>
                <SectionContent text={training.prerequisites} />
              </Content>
            )}
            {training.additional_information && (
              <Content id="additional_information">
                <SectionTitle color="textPrimary" textAlign="left" variant="h4">
                  {'Informations complémentaires'}
                </SectionTitle>
                <SectionContent text={training.additional_information} />
              </Content>
            )}
          </Main>
        </Container>
      </TableOfContent>
    </PageSection>
  );
}
