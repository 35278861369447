import React, { useState, createContext } from 'react';

interface Context {
  offset: number;
  registerPosition: (id: string, value: number) => void;
  positions: Position[];
}

export const TableOfContentContext = createContext<Context>({
  offset: 0,
  registerPosition: () => {},
  positions: [],
});

interface Props {
  children: React.ReactChild;
  offset?: number;
}

type Position = [string, number];

function ensurePositionOrder(a: Position, b: Position) {
  if (a[1] < b[1]) {
    return -1;
  } else if (a[1] > b[1]) {
    return 1;
  }
  return 0;
}

export default function TableOfContent({ children, offset = 0 }: Props) {
  const [positions, setSetPosition] = useState<Position[]>([]);

  return (
    <TableOfContentContext.Provider
      value={{
        offset,
        registerPosition: (id, value) => {
          positions.push([id, value]);
          setSetPosition(positions);
        },
        positions: positions.sort(ensurePositionOrder),
      }}
    >
      {children}
    </TableOfContentContext.Provider>
  );
}
