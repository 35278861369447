import React from 'react';

import {
  WindowScroller,
  AutoSizer,
  ColumnSizer,
  Grid,
} from 'react-virtualized';

import VirtualizedCell from './VirtualizedGridCell';

import { VirtualizedContainerProps } from './types';

interface Props<T> extends VirtualizedContainerProps<T> {
  gutterSize: number;
  columnCount?: number;
  columnMaxWidth?: number;
  columnMinWidth?: number;
  rowHeight?: number;
}

export default function VirtualizedGrid<T>({
  dataset,
  gutterSize,
  renderData,
  columnCount = 3,
  columnMaxWidth = 400,
  columnMinWidth = 200,
  rowHeight = 480,
}: Props<T>) {
  return (
    <WindowScroller>
      {({ height, scrollTop, onChildScroll }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <ColumnSizer
              columnCount={columnCount}
              columnMaxWidth={columnMaxWidth}
              columnMinWidth={columnMinWidth}
              width={width}
            >
              {({ adjustedWidth, getColumnWidth, registerChild }) => (
                <Grid
                  autoHeight
                  cellRenderer={({ columnIndex, rowIndex, key, style }) => {
                    return (
                      <VirtualizedCell
                        data={dataset[rowIndex * columnCount + columnIndex]}
                        gutterSize={gutterSize}
                        key={key}
                        renderData={renderData}
                        style={style}
                      />
                    );
                  }}
                  columnCount={columnCount}
                  columnWidth={getColumnWidth}
                  height={height}
                  onScroll={onChildScroll}
                  overscanRowCount={2}
                  ref={registerChild}
                  rowCount={Math.ceil(dataset.length / columnCount)}
                  rowHeight={rowHeight}
                  scrollTop={scrollTop}
                  width={adjustedWidth}
                />
              )}
            </ColumnSizer>
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
}
